<style>
.text-copyable {
  cursor: pointer;
}
</style>

<script>

export default {
  props: ['text', 'label', 'size'],
  computed: {

  },
  components: { },
  created() {
    if(navigator.clipboard) {
      this.hasClipboardAPI = true;
    }
  },
  mounted() {
    this.id = this._uid;
    this.inputSize = this.size || 'lg';
    this.clearTooltip();
  },
  methods: {
    markSelfError() {
      this.style = 'error';
      this.tooltip = 'Lacking permission to copy';
    },
    markSelfSuccess() {
      this.style = 'success';
      this.tooltip = `Copied.`;
    },
    clearTooltip() {
      this.style = 'default';
      this.tooltip = 'Click to copy';
      this.$root.$emit('bv::disable::tooltip', this.id);
    },
    afterClick(success) {
      if(success) this.markSelfSuccess();
      else this.markSelfError();
      if(this.resetTimeout) clearTimeout(this.resetTimeout);
      this.resetTimeout = setTimeout(() => {
        this.clearTooltip();
      }, 500);
    },
    onClicked(event) {
      event.preventDefault();
      if(this.hasClipboardAPI) {
        navigator.clipboard.writeText(this.text).then(() => {
          this.afterClick(true);
        }, (err) => {
          this.afterClick(false);
        });
      } else {
        this.$refs.bogusField.focus();
        this.$refs.bogusField.select();
        try {
          let result = document.execCommand('copy');
          if(result) {
            this.afterClick(true);
          } else {
            this.afterClick(false);
          }
        } catch(e) {
          console.log(e);
          this.afterClick(false);
        }
      }
    }
  },
  data() {
    return {
      id: null,
      tooltip: null,
      style: null,
      inputSize: null,
      hasClipboardAPI: false,
      resetTimeout: null
    };
  }
};
</script>

<template>
  <b-input-group :size="inputSize">
    <b-form-input :value="text" readonly="readonly" style="font-size: auto;"></b-form-input>
    <b-input-group-append>
      <b-button variant="primary" :id="''+id" v-on:click="onClicked($event)">Copy</b-button>
      <b-tooltip ref="tooltip" :variant="style" :target="''+id">{{ tooltip }}</b-tooltip>
    </b-input-group-append>
    <textarea ref="bogusField" style="position: absolute; top: -200vh; left: -200vw; width: 0px;" v-model="text"></textarea>
  </b-input-group>
</template>
